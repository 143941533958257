<template>
  <item-text title="UUID" :text="item.uuid" />
  <template v-if="item.serial_nr">
    <item-text title="Sērijas nr." :text="item.serial_nr" />
  </template>
  <item-text title="Kategorija" :text="item.catalog_item.category" />
  <item-text title="Ražotājs" :text="item.catalog_item.manufacturer" />
  <item-text title="Modelis" :text="item.catalog_item.model" />
  <item-text title="Pielietojums" :text="item.purpose.name" />
  <item-text title="Aktīvs" :text="item.is_active ? 'Jā' : 'Nē'" />
  <item-text title="Īpašnieks" :text="item.customer.name" />
  <template v-if="item.owner_branch">
    <item-text title="Filiāle" :text="item.owner_branch.name" />
  </template>
  <item-text
    title="Vērtība EUR"
    :text="`${item.cost ? item.cost : 'Nav norādīts'}`"
  />

  <template v-if="item.location">
    <item-text title="Atrašanās vieta" :text="item.location" />
  </template>

  <template v-if="item.purpose.id === 1">
    <item-text
      title="Nomas bāzes cena EUR"
      :text="`${item.rent_base_price ? item.rent_base_price : 'Nav norādīts'}`"
    />
  </template>
</template>

<script>
import { mapGetters } from "vuex";
import ItemText from "@/components/Components/ItemText";

export default {
  name: "ShowInventoryItemDetails",
  components: {
    ItemText,
  },
  props: ["item"],
  computed: {
    ...mapGetters({
      formsForDisplay: "formsForDisplay",
    }),
  },
  methods: {
    showForm(formName) {
      this.$store.dispatch("addFormForDisplay", formName);
    },
    displayForm(formName) {
      return this.formsForDisplay.includes(formName);
    },
  },
};
</script>

<style>
</style>